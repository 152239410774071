<template>
  <div>
    <v-dialog
      v-model="isPasswordDialog"
      max-width="450px"
      persistent
    >
      <v-card class="password-dialog">
        <v-card-text class="text-center pt-8 pb-0">
          <v-avatar
            color="primary"
            size="64"
            class="mb-4"
          >
            <v-icon size="36" color="white">mdi-shield-lock-outline</v-icon>
          </v-avatar>
          
          <h2 class="text-h5 font-weight-medium mb-2">
            Cambiar Contraseña
          </h2>
          <p class="text-body-2 text-medium-emphasis mb-6">
            Por tu seguridad, ingresa una contraseña segura
          </p>
        </v-card-text>

        <v-card-text class="pt-0 pb-4">
          <v-form ref="resetPass" v-model="valid" lazy-validation>
            <div class="password-field mb-4">
              <v-text-field
                v-model="inputsPass[0].value"
                :type="inputType(0)"
                label="Nueva Contraseña"
                :append-icon="viewPassIcon(0)"
                @click:append="togglePasswordVisibility(0)"
                dense
                outlined
                placeholder="Ingresa tu nueva contraseña"
                class="password-input"
                :rules="passwordRules"
                autocomplete="new-password"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary" class="mr-2">mdi-key-outline</v-icon>
                </template>
              </v-text-field>
              
              <div class="password-strength mt-1" v-if="inputsPass[0].value">
                <v-chip
                  x-small
                  :color="passwordStrength.color"
                  text-color="white"
                  class="mr-1"
                >
                  {{ passwordStrength.text }}
                </v-chip>
              </div>
            </div>

            <div class="password-field">
              <v-text-field
                v-model="inputsPass[1].value"
                :type="inputType(1)"
                label="Confirmar Contraseña"
                :append-icon="viewPassIcon(1)"
                @click:append="togglePasswordVisibility(1)"
                dense
                outlined
                placeholder="Confirma tu nueva contraseña"
                :rules="repeatPasswordRules"
                autocomplete="new-password"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary" class="mr-2">mdi-shield-check-outline</v-icon>
                </template>
              </v-text-field>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-btn
            text
            @click="$emit('cerrar-cambio-pass', false)"
            :disabled="loader"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loader"
            :disabled="isSubmitDisabled"
            class="px-6"
            @click="enviarPass()"
          >
            <v-icon left>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DialogoCambioPass",
  props: {
    isPasswordDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      valid: false,
      ruta: "auth/resetpass",
      viewPass: [false, false],
      passwordRules: [
        v => !!v || 'La contraseña es requerida',
        v => (v && v.length >= 8) || 'Mínimo 8 caracteres',
        v => /[A-Z]/.test(v) || 'Debe contener al menos una mayúscula',
        v => /[0-9]/.test(v) || 'Debe contener al menos un número'
      ],
      repeatPasswordRules: [
        v => !!v || 'Confirma tu contraseña',
        v => v === this.inputsPass[0].value || 'Las contraseñas no coinciden'
      ],
      inputsPass: [
        { value: null, label: "Nueva Contraseña" },
        { value: null, label: "Repetir Contraseña" }
      ]
    };
  },
  computed: {
    passwordStrength() {
      const pass = this.inputsPass[0].value;
      if (!pass) return { text: '', color: '' };
      
      const hasUpperCase = /[A-Z]/.test(pass);
      const hasLowerCase = /[a-z]/.test(pass);
      const hasNumbers = /\d/.test(pass);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
      
      const strength = [hasUpperCase, hasLowerCase, hasNumbers, hasSpecialChar].filter(Boolean).length;
      
      if (pass.length < 8) return { text: 'Débil', color: 'error' };
      if (strength <= 2) return { text: 'Media', color: 'warning' };
      if (strength === 3) return { text: 'Fuerte', color: 'success' };
      return { text: 'Muy Fuerte', color: 'success' };
    },
    isSubmitDisabled() {
      const pass1 = this.inputsPass[0].value;
      const pass2 = this.inputsPass[1].value;

      return pass1 === null || (pass1 === "" && pass2 === null) || pass2 === "";
    },
    inputType() {
      return (index) => (this.viewPass[index] ? "text" : "password");
    },
    viewPassIcon() {
      return (index) => (this.viewPass[index] ? "mdi-eye-off" : "mdi-eye");
    },
  },
  methods: {
    async enviarPass() {
      if (this.$refs.resetPass.validate()) {
        let dataUser = JSON.parse(localStorage.getItem("token"));
        let data = {
          password: this.inputsPass[1].value,
          email: dataUser.email,
        };

        try {
          this.loader = true;
          let res = await axios.post(
            process.env.VUE_APP_BASE_URL + this.ruta,
            data
          );
          console.log(">>>>>", res.data);
          this.$toast.open({
            message: res.data.msg,
            position: "top-right",
            type: res.data.type,
            duration: 4000,
          });
        } catch (e) {
          console.log(e);
          this.$toast.open({
            message: "Error comunicarse con soporte ",
            position: "top-right",
            type: "error",
            duration: 4000,
          });
        } finally {
          this.loader = false;
          this.$refs.resetPass.reset();
          this.$emit("cerrar-cambio-pass", false);
        }
      } else {
        console.log("no son iguales");
        this.$toast.open({
          message: "No  coinciden las contraseñas ",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
      }
    },

    togglePasswordVisibility(index) {
      this.$set(this.viewPass, index, !this.viewPass[index]);
    },
  },
};
</script>

<style scoped>
.password-dialog {
  border-radius: 12px;
  overflow: hidden;
}

.password-field {
  position: relative;
}

.password-input {
  margin-bottom: 0;
}

.password-strength {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.v-text-field.v-text-field--enclosed.v-input--dense .v-input__prepend-inner {
  margin-top: 8px !important;
}

.v-text-field.v-text-field--enclosed.v-input--dense .v-input__append-inner {
  margin-top: 8px !important;
}

.v-btn {
  text-transform: none;
  letter-spacing: 0.5px;
}

.v-avatar {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
</style>

import axios from 'axios'

const state = {
  listasPrecio: []
}

const getters = {
  listasPrecio: state => state.listasPrecio
}

const actions = {
  async getListasPrecios({ commit }) {
    console.log('obteniendo listas de precios');
    
    try {
      const response = await axios.get(process.env.VUE_APP_BASE_URL+'productos/listas-precios')
      commit('SET_LISTAS_PRECIO', response.data)
    } catch (error) {
      console.error('Error al obtener listas de precio:', error)
    }
  },getListasPrecioPorClienteVendedor({ commit }, { id_cliente, id_vendedor }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}productos/listas-precios/vendedor-cliente-publicas`, {
          params: {
            id_cliente,
            id_vendedor
          }
        })
        .then((response) => {
          console.log('respuesta', response.data);
          
          commit('SET_LISTAS_PRECIO', response.data.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.error('Error al obtener listas de precio por cliente y vendedor:', error)
          reject(error)
        })
    })
  }
}

const mutations = {
  SET_LISTAS_PRECIO(state, listas) {
    state.listasPrecio = listas
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

<template>
    <v-card v-bind="$attrs">
      <v-card-title class="primary darken-1 white--text">
        <v-icon left color="white">mdi-account-tie</v-icon>
        <slot name="title">Información del Vendedor</slot>
      </v-card-title>
      <slot></slot>
    </v-card>
  </template>
  
  <script>
  export default {
    name: "VCustomCard"
  }
  </script>
<template>
  <div>
    <v-app-bar app color="#f6f6f6" elevate-on-scroll elevation="4">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <WhatsAppVincular />
      <NotificationBadgeDashboard />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="cursor: pointer"
            class="mx-5 mr-10"
          >
            <v-chip link class="user-chip" color="grey lighten-5">
              <v-badge dot bottom offset-y="10" offset-x="10" color="success">
                <v-avatar size="35">
                  <v-img src="@/assets/users.png" />
                </v-avatar>
              </v-badge>
              <span class="user-name ml-2">{{ datosUser.nombre_completo }}</span>
              <v-icon small class="ml-1">mdi-chevron-down</v-icon>
            </v-chip>
          </span>
        </template>
        <v-list width="300" class="user-menu py-0">
          <v-list-item class="user-header">
            <v-list-item-avatar size="48">
              <v-img src="@/assets/users.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title font-weight-medium">
                {{ datosUser.nombre_completo }}
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
                <v-chip x-small color="success" class="px-2" text-color="white">
                  <v-icon left x-small>mdi-circle-medium</v-icon>
                  En Línea
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-for="{ icon, title } in menus"
            :key="icon"
            link
            class="menu-option"
            @click="handleMenuItemClick(title)"
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">{{ title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="grey lighten-1">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- SIDEBAR -->
    <v-navigation-drawer 
      v-model="drawer" 
      app
      dark
      class="navigation-drawer"
      color="primary"
    >
      
        <div class="">
          <v-img
            src="@/assets/Presentacion-azul.png"
            height="100%"
            width="100%"
            class="header-logo"
          ></v-img>
        </div>
      

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group v-model="group" color="accent">
          <v-list-item
            v-for="{ icono, menu, ruta } in links"
            :key="icono"
            v-if="ruta"
            link
            @click="$router.push(ruta).catch((err) => {})"
            class="menu-item"
          >
            <v-list-item-icon>
              <v-icon>{{ icono }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <DialogCambioPass
      :isPasswordDialog="isPasswordDialog"
      @cerrar-cambio-pass="(estado) => (isPasswordDialog = estado)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogCambioPass from "../dialogs/DialogCambioPass.vue";
import NotificationBadgeDashboard from "./NotificationBadgeDashboard.vue";
import WhatsAppVincular from "./WhatsAppVincular.vue";

export default {
  name: "Header",
  created() {
    this.createMenu();
  },
  components: {
    DialogCambioPass,
    NotificationBadgeDashboard,
    WhatsAppVincular,
  },
  data() {
    return {
      isPasswordDialog: false,
      drawer: null,
      links: [],
      group: null,

      menus: [
        { title: "Perfil", icon: "mdi-account" },
        { title: "Cambiar Contraseña", icon: "mdi-key" },
        { title: "Salir", icon: "mdi-logout" },
      ],
    };
  },

  methods: {
    handleMenuItemClick(title) {
      switch (title) {
        case "Salir":
          this.logout();
          break;
        case "Cambiar Contraseña":
          this.isCambioPass();
          break;
        default:
          // Acción por defecto si no coincide con "Salir" o "Cambiar Contraseña"
          break;
      }
    },
    isCambioPass() {
      this.isPasswordDialog = true;
      console.log(this.isPasswordDialog);
    },
    logout() {
      this.$store.dispatch("logout");
    },

    createMenu() {
      this.links = this.datosUser.menu;
    },
  },

  computed: {
    ...mapGetters({
      DatosUsuario: "datosUser",
    }),

    datosUser: {
      get() {
        return this.DatosUsuario;
      },
      set(newName) {
        return newName;
      },
    },
  },
};
</script>

<style>
.navigation-drawer {
  border-radius: 0 16px 16px 0;
  transition: all 0.3s ease;
}

.v-navigation-drawer__content {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.menu-item {
  border-radius: 8px;
  margin: 4px 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.menu-item:hover {
  transform: translateX(4px);
  background: rgba(255, 255, 255, 0.08) !important;
}

.menu-item.v-list-item--active {
  background: rgba(255, 255, 255, 0.12) !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.menu-item.v-list-item--active .v-list-item__title {
  color: white !important;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.menu-item.v-list-item--active .v-icon {
  color: white !important;
}

.menu-item.v-list-item--active::before {
  opacity: 0 !important;
}

.menu-item.v-list-item--active:hover {
  transform: translateX(4px);
  background: rgba(255, 255, 255, 0.15) !important;
}

.drawer-header {
  border-radius: 0 16px 0 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.1);
}

.logo-container {
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  padding: 8px;
  background: rgba(255, 255, 255, 0.03);
}

.logo-container:hover {
  transform: translateY(-1px);
  background: rgba(255, 255, 255, 0.05);
}

.v-list {
  padding: 8px;
}

.v-list-item {
  margin-bottom: 4px;
}

.v-list-item__icon {
  margin-right: 16px;
  transition: transform 0.3s ease;
}

.user-chip {
  height: 42px !important;
  padding: 0 12px !important;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.user-chip:hover {
  background: #f8f9fa !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.user-name {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 4px;
}

.user-menu {
  border-radius: 8px !important;
  overflow: hidden;
}

.user-header {
  background: linear-gradient(to right, rgba(var(--v-primary-base), 0.05), transparent);
  padding: 16px !important;
}

.menu-option {
  min-height: 48px !important;
  margin: 4px 8px !important;
  border-radius: 6px !important;
  transition: all 0.2s ease;
}

.menu-option:hover {
  background: rgba(var(--v-primary-base), 0.05) !important;
}

.menu-option .v-list-item__icon {
  margin: 12px 16px 12px 8px !important;
}

.menu-option .v-list-item__action {
  margin: 0 !important;
}

@media (max-width: 600px) {
  .user-chip {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
}
</style>

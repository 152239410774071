import VCustomCard from './VCustomCard.vue'

const requireComponent = require.context('.', true, /\.vue$/)
const Components = {
  install(Vue) {
    Vue.component('VCustomCard', VCustomCard)
    requireComponent.keys().forEach(fileName => {
      const componentConfig = requireComponent(fileName)
      const componentName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
      
      Vue.component(`V${componentName}`, componentConfig.default || componentConfig)
    })
  }
}

export default Components
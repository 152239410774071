<template>
  <div class="login-container d-flex align-center justify-center">
    <canvas id="networkCanvas" class="network-canvas"></canvas>
    <div class="background-pattern"></div>
    <div class="overlay"></div>
    <v-card class="login-card pa-6" max-width="400" elevation="8">
      <v-card-text class="text-center">
        <v-img
          class="mx-auto mb-6"
          max-width="240"
          src="../assets/Presentacionblanco.png"
          contain
        ></v-img>
        
        <h1 class="text-h5 font-weight-medium mb-2">¡Bienvenido! 👋</h1>
        <p class="text-subtitle-1 text-medium-emphasis">Por favor inicia sesión para continuar</p>

        <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
          <v-text-field
            v-model="email"
            :rules="nameRules"
            label="Usuario"
            outlined
            dense
            class="mb-4"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :type="verContraseña ? 'text' : 'password'"
            :rules="ReglasContraseña"
            label="Contraseña"
            outlined
            dense
            :append-icon="verContraseña ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="verContraseña = !verContraseña"
            class="mb-2"
          ></v-text-field>

          <div class="d-flex align-center justify-space-between mb-6">
            <v-checkbox
              label="Recordarme"
              class="mt-0"
              dense
              hide-details
            ></v-checkbox>
            <a href="#" class="text-decoration-none" style="color: #7367F0">¿Olvidaste tu contraseña?</a>
          </div>

          <v-btn
            block
            :loading="loading"
            :disabled="loading"
            color="#0d47a1"
            class="white--text mb-6"
            height="44"
            @click="submit"
          >
            Iniciar Sesión
          </v-btn>

         
          <!-- <div class="text-center mb-6">
            <span class="text-medium-emphasis">¿Nuevo en nuestra plataforma?</span>
            <a href="#" class="text-decoration-none ml-1" style="color: #7367F0">Crear una cuenta</a>
          </div> -->

          <v-divider class="mb-4"></v-divider>

          <div class="text-center mb-2">
            <span class="text-medium-emphasis text-caption">o conéctate usando</span>
          </div>

          <div class="d-flex justify-center">
            <v-btn
              v-for="(icon, index) in socialIcons"
              :key="index"
              icon
              :color="icon.color"
              class="mx-2"
              elevation="2"
            >
              <v-icon>{{ icon.icon }}</v-icon>
            </v-btn>
          </div>
          <div class="mt-4 text-center">
            <span class="powered-by-text">Powered by BISSTRACK ®</span>
          </div>
        </v-form>
      </v-card-text>

      <v-snackbar
        v-model="dialogErr"
        :timeout="timeout"
        rounded="pill"
        absolute
        bottom
        color="red accent-2"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="dialogErr = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import * as animationData from "../assets/lottie/sales.json";

export default {
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
      text: "error en login",
      timeout: 3000,
      dialogErr: false,
      loading: false,
      valid: false,
      verContraseña: false,
      password: "",
      email: "",
      socialIcons: [
        { icon: 'mdi-facebook', color: '#4267B2' },
        { icon: 'mdi-twitter', color: '#1DA1F2' },
        { icon: 'mdi-github', color: '#333' },
        { icon: 'mdi-google', color: '#DB4437' }
      ],
      nameRules: [
        (v) => !!v || "Ingresar Usuario",
      ],
      ReglasContraseña: [
        (v) => !!v || "Ingresar contraseña",
      ],
    };
  },
  mounted() {
    this.initCanvas();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      const response = await axios.post(process.env.VUE_APP_BASE_URL + "usuarios/auth/signin/", {
        email: this.email,
        password: this.password,
      });
      console.log("response", response);
      
      const { usuario } = response.data;
      if (!usuario) {
        this.handleLoginError();
        return;
      }

      this.clearForm();

      localStorage.setItem("token", JSON.stringify(usuario));

      await this.$store.dispatch("login", usuario);

    },

    handleLoginError() {
      this.dialogErr = true;
      this.clearForm();
    },

    clearForm() {
      this.email = "";
      this.password = "";
    },
    initCanvas() {
      const canvas = document.getElementById('networkCanvas');
      const ctx = canvas.getContext('2d');
      let particles = [];
      let animationFrameId;
      
      const resize = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      };
      resize();

      // Configuración de partículas
      const particleCount = 100;
      const connectionDistance = 150;
      const particleSize = 2;
      
      // Crear partículas
      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          speedX: (Math.random() - 0.5) * 0.5,
          speedY: (Math.random() - 0.5) * 0.5,
        });
      }

      // Función de animación
      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        // Actualizar y dibujar partículas
        particles.forEach((particle, i) => {
          // Mover partículas
          particle.x += particle.speedX;
          particle.y += particle.speedY;

          // Rebotar en los bordes
          if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
          if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;

          // Dibujar partícula
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particleSize, 0, Math.PI * 2);
          ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
          ctx.fill();

          // Conectar partículas cercanas
          for (let j = i + 1; j < particles.length; j++) {
            const dx = particles[j].x - particle.x;
            const dy = particles[j].y - particle.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < connectionDistance) {
              ctx.beginPath();
              ctx.moveTo(particle.x, particle.y);
              ctx.lineTo(particles[j].x, particles[j].y);
              const opacity = 1 - (distance / connectionDistance);
              ctx.strokeStyle = `rgba(255, 255, 255, ${opacity * 0.2})`;
              ctx.stroke();
            }
          }
        });

        animationFrameId = requestAnimationFrame(animate);
      };

      animate();
      this.animationFrameId = animationFrameId;
    },
    handleResize() {
      const canvas = document.getElementById('networkCanvas');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #000428 0%, #004e92 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  background: rgba(255, 255, 255, 0.95) !important;
  backdrop-filter: blur(10px);
  border-radius: 20px !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3) !important;
  position: relative;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  margin: 20px;
}

.network-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.03;
  background-image: 
    linear-gradient(45deg, #fff 25%, transparent 25%),
    linear-gradient(-45deg, #fff 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #fff 75%),
    linear-gradient(-45deg, transparent 75%, #fff 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 70%);
}



.powered-by-text {
  color: rgba(var(--v-theme-on-surface), 0.7);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.15px;
}

/* Keyframe animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Mobile styles */
@media (max-width: 600px) {
  .login-container {
    padding: 0;
    background: linear-gradient(-45deg, #000428, #004e92, #005fb8, #0074e4);
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite;
  }

  .login-card {
    margin: 0;
    max-width: none;
    min-height: 100vh;
    border-radius: 0 !important;
    border: none;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9) !important;
  }

  :deep(.v-card-text) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px !important;
  }

  .v-img {
    max-width: 160px !important;
    margin: 20px auto !important;
    animation: float 6s ease-in-out infinite;
  }

  :deep(.text-h5) {
    animation: fadeInUp 0.6s ease-out;
    animation-fill-mode: both;
  }

  :deep(.text-subtitle-1) {
    animation: fadeInUp 0.6s ease-out 0.2s;
    animation-fill-mode: both;
  }

  :deep(.v-text-field) {
    animation: fadeInUp 0.6s ease-out 0.4s;
    animation-fill-mode: both;
  }

  :deep(.v-btn.v-btn--block) {
    animation: fadeInUp 0.6s ease-out 0.6s;
    animation-fill-mode: both;
    height: 48px !important;
    background: linear-gradient(45deg, #004e92, #000428) !important;
    position: relative;
    overflow: hidden;
  }

  :deep(.v-btn.v-btn--block::after) {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(45deg);
    animation: shine 3s infinite;
  }

  @keyframes shine {
    0% { transform: translateX(-100%) rotate(45deg); }
    100% { transform: translateX(100%) rotate(45deg); }
  }

  :deep(.v-btn.v-btn--icon) {
    animation: fadeInUp 0.6s ease-out 0.8s;
    animation-fill-mode: both;
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
  }

  :deep(.v-btn.v-btn--icon:active) {
    transform: scale(0.95);
    animation: pulse 0.3s ease-in-out;
  }

  /* Animated background dots */
  .background-pattern {
    opacity: 0.05;
    background-size: 30px 30px;
    animation: patternMove 20s linear infinite;
  }

  @keyframes patternMove {
    0% { background-position: 0 0; }
    100% { background-position: 50px 50px; }
  }
}

/* Improve form elements inside card */
:deep(.v-text-field--outlined) {
  border-radius: 12px;
}

:deep(.v-text-field--outlined .v-input__slot) {
  transition: all 0.3s ease;
}

:deep(.v-text-field--outlined:hover .v-input__slot) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

:deep(.v-text-field--outlined.v-input--is-focused .v-input__slot) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Add animation to the logo */
.v-img {
  transition: transform 0.5s ease;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

/* Improve social buttons */
:deep(.v-btn.v-btn--icon) {
  width: 44px;
  height: 44px;
  margin: 0 8px;
  background-color: rgba(255, 255, 255, 0.9) !important;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

:deep(.v-btn.v-btn--icon:hover) {
  background-color: white !important;
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Override existing styles to match the theme */
:deep(.v-btn) {
  text-transform: none !important;
  letter-spacing: 0.5px !important;
}

:deep(.v-btn.v-btn--block) {
  background: linear-gradient(45deg, #004e92, #000428) !important;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

:deep(.v-btn.v-btn--block:hover) {
  box-shadow: 0 4px 15px rgba(0, 78, 146, 0.25) !important;
}

:deep(.v-btn.v-btn--block::before) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #000428, #004e92);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
}

:deep(.v-btn.v-btn--block:hover::before) {
  opacity: 1;
}

:deep(.v-btn.v-btn--block .v-btn__content) {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.v-text-field--outlined fieldset) {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>

<template>
  <div class="admin">
    <v-alert dense text type="success">
      <strong>Ingreso Autorizado</strong>
    </v-alert>
    <!-- <VCustomCard>
  <template #title>
    Título Personalizado
  </template>
  Contenido...
</VCustomCard> -->
    <v-row v-if="datosUser.id_rol != 1">
      <v-col cols="12" md="5">
        <v-card
          elevation="2"
          class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
        >
          <v-card-title>
            {{ saludo }} <br />
            {{ datosUser.nombre_completo }}
          </v-card-title>
        

          <div>
            <div class="bubble-shape-sm-secondary bubble-secondary"></div>
            <v-avatar class="ma-3" size="100" tile>
              <v-icon size="100">mdi-account-check</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="mx-auto">
          <v-card-title>
            <v-icon large left> mdi-monitor-cellphone </v-icon>
            <span class="text-h6 font-weight-boldt">Diazioni Digital</span>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            "Por consultas o reportes de errores con el funcionamiento del
            sistema por favor comunicarse con su administrador"
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Mike</v-list-item-title>
                <small>Area Sistemas</small>
              </v-list-item-content>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <DataCalendarDashboardVue @dataCards="handleInfoCard" />
      <v-row>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12">
              <v-card
                elevation="2"
                class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
              >
                <v-card-title>
                  {{ saludo }} <br />
                  {{ datosUser.nombre_completo }}
                </v-card-title>

                <div>
                  <div class="bubble-shape-sm-secondary bubble-secondary"></div>
                  <v-avatar class="ma-3" size="100" tile>
                    <v-icon size="100">mdi-account-check</v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <cardListInfoComponent :infoCardList="generalsCards" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8">
          <dataInfoCardComponent
            :title="'Ventas - Pedidos'"
            :subTitle="'Estadisticas General'"
            :datosCard="importansCards"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DataPanelService from "@/services/DataPanelService.js";
import DataCalendarDashboardVue from "@/components/dashboard/DataCalendarDashboard.vue";
import dataInfoCardComponent from "@/components/inicioView/dataInfoCardComponent.vue";
import cardListInfoComponent from "@/components/inicioView/cardListInfoComponent.vue";

export default {
  name: "Inicio",
  components: {
    DataCalendarDashboardVue,
    dataInfoCardComponent,
    cardListInfoComponent,
  },
  data() {
    return {
      datosUser: "",
      dataExcel: {},
      importansCards: [],
      generalsCards: [],
    };
  },

  mounted() {
    this.datosUser = JSON.parse(localStorage.getItem("token"));
    DataPanelService.getData()
      .then((resp) => {
        this.handleInfoCard(resp.cards);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    handleInfoCard(data) {
      const { importants, generals } = data.reduce(
        (result, item) => {
          if (item.important) {
            result.importants.push(item);
          } else {
            result.generals.push(item);
          }
          return result;
        },
        { importants: [], generals: [] }
      );

      this.importansCards = importants;
      this.generalsCards = generals;
      console.log(this.importansCards);
    },
  },
  computed: {
    saludo() {
      const hora = new Date().getHours();
      if (hora >= 5 && hora < 14) {
        return "Buenos días!";
      } else if (hora >= 14 && hora < 20) {
        return "Buenas tardes!";
      } else {
        return "Buenas noches!";
      }
    },
  },
};
</script>

<style>
.bubble-shape-sm.bubble-primary:before {
  background: linear-gradient(
    140.9deg,
    rgb(227, 242, 253) -14.02%,
    rgba(21, 101, 192, 0) 77.58%
  );
}
.bubble-shape-sm.bubble-primary:after {
  background: linear-gradient(
    210.04deg,
    rgb(227, 242, 253) -50.94%,
    rgba(21, 101, 192, 0) 83.49%
  );
}

.bubble-shape-sm-secondary.bubble-secondary:before {
  background: linear-gradient(
    98deg,
    rgb(255, 193, 7) -14.02%,
    rgba(144, 202, 249, 0) 77.58%
  );
}
.bubble-shape-sm-secondary.bubble-secondary:after {
  background: linear-gradient(
    393deg,
    rgb(255, 193, 7) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}

.bubble-shape-sm-secondary:before {
  content: "";
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  top: -160px;
  right: -30px;
}
.bubble-shape-sm-secondary:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  top: -57px;
  right: -95px;
}
.variant--blue--card {
  background-color: #26356c;
}
</style>

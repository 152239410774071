<template>
  <v-app id="inspire" :style="{ backgroundColor: bgColor }">

    <Navigation v-if="loginState" />

    <!-- <Sidebar v-if="loginState"/> -->

    <v-main>
    
      <v-container fluid v-if="loginState">

        <router-view></router-view>

      </v-container>
      
      <router-view v-else></router-view>
    </v-main>

  </v-app>

</template>



<script>

import Navigation from "./components/dashboard/Navigation.vue";

import Sidebar from "./components/Sidebar.vue";

import { mapGetters } from "vuex";



export default {

  components: {

    Navigation,

    Sidebar,

  },

  data: () => ({

    cards: ["Today", "Yesterday"],

    bgColor: "#f6f6f6",

  }),

  computed: {

    ...mapGetters(["loginState"]),

  },

  created() {

    const token = localStorage.getItem("token");

    if (token) {

      this.$store.dispatch("login", JSON.parse(token));

    }

    // }else{



    //   if (this.$router.currentRoute.name !== "Login")

    //   this.$router.push({ name: "Login" });

    // }

  },

  // watch: {

  //   // whenever question changes, this function will run

  //   drawer(newDrawer, old) {

  //     this.drawer=this.drawer

  //   }

  // }

};

</script>

<style>

.v-data-table__mobile-table-row {

  display: initial;

  /* margin: 10px; */

  border: 4px solid #bfbcbcde;

  border-radius: 20px;

  padding: 5px;

  margin: 9px;

  padding-bottom: 0;

}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {

  height: initial;

  min-height: 48px;

  border-bottom: 0.4px solid #bfbcbcde;

}

.v-data-table-header-mobile {

  display: none !important;

}

</style>

